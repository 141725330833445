<template>
  <div>
    <form-wizard
      :color="isBusy ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="isBusy ? 'Updating...' : 'Update'"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- karma-program details tab -->
      <tab-content
        title="Karma Program Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="karma-program-form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Karma Program name"
                  label-for="karma-program-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Karma Program name"
                    rules="required"
                  >
                    <b-form-input
                      id="karma-program-name"
                      v-model="data_local.name"
                      name="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Karma Program name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Start Date" label-for="nhr-date">
                  <flat-pickr
                    v-model="data_local.start_date"
                    :config="{
                      dateFormat: 'Y-m-d',
                      altFormat: 'd F Y',
                      altInput: 'true',
                    }"
                    class="form-control"
                    name="start_date"
                    placeholder="Select Start Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="End Date" label-for="nhr-date">
                  <flat-pickr
                    v-model="data_local.end_date"
                    :config="{
                      dateFormat: 'Y-m-d',
                      altFormat: 'd F Y',
                      altInput: 'true',
                    }"
                    class="form-control"
                    name="end_date"
                    placeholder="Select End Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="status"> Status </label>
                  <v-select
                    v-model="data_local.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="status_option"
                    :reduce="(val) => val.value"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="status"
                    name="status"
                    placeholder="Select Status"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="employee">Admin</label>
                  <v-select
                    v-model="data_local.admin_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="employee_option"
                    :reduce="(label) => label.hashid"
                    :clearable="false"
                    input-id="employee"
                    label="name"
                    placeholder="Select Admin.."
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.first_name }} ({{ option.email }})
                    </template>

                    <template slot="selected-option" slot-scope="option">
                      {{ option.first_name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group
                    v-if="item.type == 'number'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'text'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'url'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'rating'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'password'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'textarea'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.type == 'date'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>

      <tab-content title="Catalog" icon="feather icon-check-circle">
        <b-row>
          <b-col class="md-8" />
          <b-col class="md-4">
            <b-form autocomplete="off" class="faq-search-input">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  placeholder="Search Karma Catalog..."
                />
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>
        <section id="faq-tabs" class="mt-2 mb-2">
          <div v-if="faqData.length">
            <b-tabs
              vertical
              content-class="col-12 col-md-8 col-lg-9"
              pills
              nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
              nav-class="nav-left"
            >
              <b-tab
                v-for="(obj, index) in faqData"
                :key="index"
                :active="!index"
              >
                <!-- title -->
                <template #title>
                  <span class="font-weight-bold">{{ obj.name }}</span>
                </template>

                <div v-if="obj">
                  <div class="d-flex align-items-center mt-1 mb-2">
                    <div>
                      <b-form-checkbox
                        v-model="allSelected"
                        :indeterminate="indeterminate"
                        aria-describedby="karma_catalogue"
                        aria-controls="karma_catalogue"
                        @change="toggleAll"
                        class="no-select mb-1"
                      >
                        {{ allSelected ? "Un-select All" : "Select All" }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="selected[obj.name]"
                        :indeterminate="indeterminate"
                        aria-describedby="karma_catalogue"
                        aria-controls="karma_catalogue"
                        @change="toggleAll"
                        class="no-select"
                      >
                        <h4 class="mb-0">
                          {{ obj.name }}
                        </h4>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <b-list-group class="group-scroll">
                    <b-list-group-item
                      v-for="(data, indexes) in obj.karma_catalogue"
                      :key="indexes"
                    >
                      <b-form-checkbox
                        v-model="data_local.karma_catalogue_ids"
                        :value="data.id"
                        class="no-select"
                        >{{ data.karma_name }}</b-form-checkbox
                      ></b-list-group-item
                    >
                  </b-list-group>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-else class="text-center">
            <span>No Checklist Found</span>
          </div>
        </section>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import moment from "moment";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import karmaProgramStoreModule from "../karmaProgramStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      isBusy: false,
      redirectKarmaProgram: "",
      data_local: {
        name: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        status: "started",
        admin: "",
        get_customfields_data: [],
        karma_catalogue_ids: [],
      },
      customFields: [],
      status_option: [],
      employee_option: [],
      selected: [],
      faqData: [],
      faqSearchQuery: "",
      allSelected: false,
      indeterminate: false,
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-karma-programs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, karmaProgramStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.getCategoryWithCatalogue();
      },
    },
    allSelected: function (val) {
      if (val) {
        this.faqData.forEach((karma_category) => {
          this.selected[karma_category.name] = true;
          karma_category.karma_catalogue.forEach((karma_catalogue) => {
            this.data_local.karma_catalogue_ids.push(karma_catalogue.id);
          });
        });
      } else {
        this.faqData.forEach((karma_category) => {
          this.selected[karma_category.name] = false;
        });
        this.data_local.karma_catalogue_ids = [];
      }
    },
  },
  created() {
    this.getCustomFields();
    this.fetch_user_data(this.$route.params.id);
    this.getCategoryWithCatalogue();
  },
  methods: {
    toggleAll(checked) {
      const self = this;
      self.faqData.forEach((karma_category) => {
        if (self.selected[karma_category.name]) {
          karma_category.karma_catalogue.forEach((karma_catalogue) => {
            self.data_local.karma_catalogue_ids.push(karma_catalogue.id);
          });
        } else {
          karma_category.karma_catalogue.forEach((karma_catalogue) => {
            var filteredArray = self.data_local.karma_catalogue_ids.filter(
              function (e) {
                return e !== karma_catalogue.id;
              }
            );
            self.data_local.karma_catalogue_ids = filteredArray;
          });
        }
      });
    },
    openWindow(link) {
      window.open(link);
    },
    fetch_user_data(userId) {
      this.isBusy = true;
      this.$store
        .dispatch("app-karma-programs/fetchKarmaProgram", userId)
        .then((res) => {
          this.isBusy = false;
          this.data_local = res.data.data;
        })
        .catch((err) => {
          this.isBusy = false;
          console.error(err);
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },
    formSubmitted() {
      const self = this;

      this.isBusy = true;
      self.data_local._method = "PATCH";
      let data = {};
      data.url = "karma-programs/" + self.$route.params.id;
      data.params = self.data_local;

      this.isBusy = true;
      this.$store
        .dispatch("app/storeNoHeader", data)
        .then((res) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Karma Program Updated",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Program Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.$router.push("/karma-programs");
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Karma Program updating Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Karma Program updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      this.isBusy = true;
      self.$store
        .dispatch("app-karma-programs/getCustomFields")
        .then((res) => {
          this.isBusy = false;
          self.customFields = res.data.data;
          self.employee_option = res.data.employee_option;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getCategoryWithCatalogue() {
      const self = this;

      let data = {};
      data.url = "get-category-with-catalogue";
      data.params = { q: this.faqSearchQuery };
      this.isBusy = true;

      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.faqData = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Categories Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Categories Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
.group-scroll {
  max-height: calc(60vh - 9rem);
  overflow-y: auto;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
